import './App.css';
import Waxtest from './views/Waxtest';


function App() {
  return (

    <div className="App">
      <header className="App-header">
        <p>
          Auto Cat Game
        </p>
        <Waxtest />
      </header>

    </div>
  );
}

export default App;
